<template>
<div id="main" style="overflow:hidden">
  <section class="content-center-full">
    <section>
      <PeopleSearch @onPersonSelected="selectPerson" ref="peopleSearchBox"/>
      <OrgChart ref="orgchart" />
    </section>
  </section>

</div>
</template>

<script>
//import {getIdToken} from '@/services/SecurityService';
import OrgChart from '@/components/people.vue'
import PeopleSearch from '@/components/peopleSearch.vue'
//import ModalDialog from '@/components/ModalDialog.vue';

//const apiHostUrl = process.env.VUE_APP_BACKEND_URL;

export default {
  name: 'HomePage',
  components: {
    OrgChart, 
    PeopleSearch
  },
  data() {
    return {
      options : [],
    };
  },
  methods: {
    selectPerson(record) {
      this.$refs.orgchart.switchRoot(record.id)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

/* 
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  padding: 20px;
  min-height: 100vh;
  background-color: rgb(234, 242, 255);
} */

input {
  display: block;
  width: 350px;
  margin: 20px auto;
  padding: 10px 45px;

  background-size: 15px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.position{
position: absolute;
width: 50%;
left: 25%;
top: 25px;
}
</style>