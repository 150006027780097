import { Log, UserManager, WebStorageStateStore } from 'oidc-client';

var mgr = new UserManager({
    userStore: new WebStorageStateStore(),
    authority: "https://doordash.okta.com",
    client_id: "0oa16hkac2dCvmZ3C0x8",
    redirect_uri: `${window.location.origin}/static/callback.html`,
    response_type: "code",
    post_logout_redirect_uri: "https://doordash.okta.com/login/signout",
    silent_redirect_uri: `${window.location.origin}/static/silent-renew.html`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    response_mode: "fragment",
    scope: "openid profile email",
});

Log.logger = console;
Log.level = Log.NONE;  // Log.INFO; // enable logging here

class SecurityService {
    // A class to manage the user's login status and
    // the user's information from the Okta service.

    async _GetUserData() {
        // Fetch the user object.

        let user = await mgr.getUser();
        if (user == null) {
            this.SignIn(null, true);
            return null;
        }

        return user;
    }

    async IsSignedIn() {
        // Check if the user is signed in.

        return (await this._GetUserData()) != null;
    }

    async GetIDToken() {
        // Get the user's id token from the Okta service.

        let user = await this._GetUserData();
        if (user != null) {
            return user.id_token;
        }
    }

    async RenewToken() {
        // Renew the user's token manually.

        let user = await mgr.signinSilent();
        if (user == null) {
            this.SignIn(null, true);
        }

        return user;
    }

    async GetProfile() {
        // Get the user's profile from the Okta service.

        let user = await this._GetUserData();
        if (user != null) {
            return user.profile;
        }
    }

    async GetSessionState() {
        // Get the session state of the user.

        let user = await this._GetUserData();
        if (user != null) {
            return user.session_state;
        }
    }

    async GetAccessToken() {
        // Get the access token from the Okta service.

        let user = await this._GetUserData();
        if (user != null) {
            return user.access_token;
        }
    }

    async GetUserScopes() {
        // Get the logged in user's scopes from the Okta service.

        let profile = await this.GetProfile();
        if (profile) {
            return profile.scope;
        }
    }

    async GetUserRoles() {
        // Get the logged in user's roles from the Okta service.

        let profile = await this.GetProfile();
        if (profile) {
            return profile.role;
        }
    }

    async GetUserEmail() {
        // Get the logged in user's email from the Okta API.
        let user = await this._GetUserData();

        if (user != null) {
            return user.profile.email;
        }
    }

    async IsAllowedDomain() {
        // Check if the user's email domain is allowed.

        let allowedDomains = ["@wolt.com", "@external.wolt.com", "@doordash.com", "@ext.doordash.com"];
        let email = await this.GetUserEmail();
        return allowedDomains.some(domain => email.endsWith(domain));
    }

    async SignIn(redirectUrl=null, autoSignIn=false) {
        if (autoSignIn && window.localStorage.getItem("ss_prevent_auto_login") == "true") {
            return;
        }

        window.localStorage.removeItem("ss_prevent_auto_login");

        // Redirect the user to the Okta service for login.
        console.log("Signing in...");
        mgr.signinRedirect({ state: redirectUrl }).catch(err => {
            console.error(err);
        });
    }

    async SignOut(silent=false) {
        // Sign out the user from the Okta service.
        console.log("Signing out...");
        window.localStorage.setItem("ss_prevent_auto_login", true);

        // Use the silent sign out method if silent is true.
        // Normal sign out method is redirecting the user
        // to the end session endpoint.
        if (silent) {
            mgr.signoutRedirect().then(resp => {
                console.log("Signed out: ", resp);
            }).catch(err => {
                console.error(err);
            });
        } else {
            mgr.signoutRedirect().then(resp => {
                console.log("Signed out: ", resp);
            }).catch(err => {
                console.error(err);
            });
        }
    }
}

const SecService = new SecurityService();


mgr.events.addUserLoaded(user => {
    console.log("New User Loaded：", user);
    console.log("Access_token: ", user.access_token);
});

mgr.events.addAccessTokenExpiring(() => {
    console.log("AccessToken expiring...");
});

mgr.events.addAccessTokenExpired(() => {
    console.log("AccessToken expired!");
    SecService.SignOut(true);
    window.location.reload();
});

mgr.events.addSilentRenewError(() => {
    console.error("Silent renew error!");
});

mgr.events.addUserSignedOut(() => {
    console.log("User signed out.");
    mgr.signoutRedirect().then(resp => {
        console.log("Signed out: ", resp);
    }).catch(err => {
        console.error(err);
    });
});


export default SecService;
